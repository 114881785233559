<template>
  <el-card class="card acct-info" :body-style="{ display: 'flex', flexDirection: 'column', height: '100%', padding: 0 }">
    <el-row class="acct-info__header">
      <el-col class="acct-info__header__content">
        <div class="acct-info__header__content__title">
          <span class="acct-info__header__content__title__main">Account Information</span>
          <span class="acct-info__header__content__title__sub">View your account settings</span>
        </div>
      </el-col>
    </el-row>
    <el-row class="acct-info__body">
      <el-col class="acct-info__body__content">
        <el-form label-width="250px" label-position="left" class="acct-info__body__content__form">
          <el-form-item class="el-form-item--header">
            <span class="form-header">Account</span>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Username">
            <el-input readonly class="el-input--dark" v-model="account.username"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Email">
            <el-input readonly prefix-icon="el-icon-message" class="el-input--dark" v-model="account.email"></el-input>
          </el-form-item>
          <el-form-item class="el-form-item--custom" label="Groups">
            <div class="label-group">
              <gf-label inline v-for="group in account.groups" :key="group.id">{{ group.name }}</gf-label>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.card {
  flex-direction: column;
  height: 100%;
}

.label-group {
  span {
    margin-right: 10px;
  }
}

.acct-info {
  &__header {
    min-height: 70px;
    border-bottom: 1px solid #EBEDF3;

    &__content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9.75px 29.25px;

      &__title {
        display: flex;
        flex-direction: column;
        margin: 6.5px 6.5px 6.5px 0;

        &__main {
          color: #181C32;
          font-weight: 500;
          font-size: 16.575px;
        }

        &__sub {
          color: #b5b5c3;
          font-weight: 500;
          font-size: 12.025px;
          margin-top: 3.25px;
        }
      }
    }
  }

  &__body {
    &__content {
      height: 100%;
      padding: 9.75px 29.25px;
    }
  }
}
</style>

<script>
import UserProfileService from '@/services/v1/UserProfile'

export default {
  props: {
    active: Number
  },
  data () {
    return {
      account: {
        username: null,
        email: null,
        groups: []
      },

      // services
      upService: null
    }
  },
  methods: {
    // fetch
    async getAccountInfo () {
      try {
        const upService = new UserProfileService()
        const result = await upService.getAccountInfo()
        this.account = result.data
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  mounted () {
    this.$emit('update:active', 2)
    this.getAccountInfo()
  }
}
</script>
