import Service from '../Service'

export default class extends Service {
  constructor () {
    super(['user-profile'])
  }

  getPersonalInfo () {
    return this.get(null, '/personal')
  }

  updatePersonalInfo (data) {
    return this.put(data, '/personal')
  }

  getAccountInfo () {
    return this.get(null, '/account')
  }

  checkPassword (data) {
    return this.post(data, '/checkPassword')
  }

  updatePassword (data) {
    return this.put(data, '/password')
  }

  uploadAvatar (data) {
    return this.upload(data, '/upload')
  }
}
